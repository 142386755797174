<template>
    <header class="header">
        <div class="header-left d-flex justify-content-center align-items-center">
            <span class="me-2 mb-0">{{ this.homePage.toUpperCase() }}</span>

            <h6 v-if="this.role === 'admin'" class="mx-2 mb-0">
                User IBKR Status :
                <span :style="{ color: this.setting.ibkrOrder ? 'green' : 'red' }">
                    {{ this.setting.ibkrOrder ? 'connected' : 'disconnected' }}
                </span>
                <svg @click="() => this.toggleIbkrOrder(!this.setting.ibkrOrder)" xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16" viewBox="0 0 24 24" class="mx-2 bi bi-arrow-repeat"
                    style="cursor: pointer; margin-bottom: 2px">
                    <path fill="#555"
                        d="M13 2v10h-2V2zm3.78 1.728l.809.589a9.5 9.5 0 1 1-11.178 0l.808-.59l1.178 1.617l-.808.59a7.5 7.5 0 1 0 8.822 0l-.808-.59z" />
                </svg>
            </h6>

            <h6 class="mx-2 mb-0">
                IBKR Client Status :
                <span :style="{ color: this.ibkrClientStatus ? 'green' : 'red' }">
                    {{ this.ibkrClientStatus ? 'online' : 'offline' }}
                </span>
                <svg v-if="!this.ibkrClientStatus" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                    fill="currentColor" class="mx-2 bi bi-arrow-repeat" :class="{ spinningClient }"
                    style="cursor: pointer" viewBox="0 0 16 16" @click="triggerSpinClient">
                    <path
                        d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                    <path fill-rule="evenodd"
                        d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z" />
                </svg>
            </h6>

            <h6 class="mx-2 mb-0">
                IBKR Server Status :
                <span :style="{ color: ibkrServerStatus ? 'green' : 'red' }">
                    {{ ibkrServerStatus ? 'online' : 'offline' }}
                </span>
                <svg v-if="this.ibkrServerStatus" @click="disconnectIbkr" xmlns="http://www.w3.org/2000/svg" width="16"
                    height="16" viewBox="0 0 24 24" class="mx-2 bi bi-arrow-repeat"
                    style="cursor: pointer; margin-bottom: 2px">
                    <path fill="#555"
                        d="M13 2v10h-2V2zm3.78 1.728l.809.589a9.5 9.5 0 1 1-11.178 0l.808-.59l1.178 1.617l-.808.59a7.5 7.5 0 1 0 8.822 0l-.808-.59z" />
                </svg>
                <svg v-if="!this.ibkrServerStatus" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                    fill="currentColor" class="mx-2 bi bi-arrow-repeat" :class="{ spinningServer }"
                    style="cursor: pointer" viewBox="0 0 16 16" @click="triggerSpinServer">
                    <path
                        d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                    <path fill-rule="evenodd"
                        d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z" />
                </svg>
            </h6>

            <div class="d-flex align-items-center">
                <h6 class="mb-0 mx-2">Recurring Sell : </h6>
                <label class="switch">
                    <input type="checkbox" v-model="this.setting.isRecurringSell"
                        :onchange="() => this.toggleRecurringSell()">
                    <span class="slider"></span>
                </label>
            </div>
        </div>


        <div class="header-right">
            <a :href="'/' + this.homePage" class="header-link">Home</a>
            <!-- <a v-if="this.role === 'admin'" href="/spxData" class="header-link">Spx Data</a> -->
            <a v-if="this.role === 'admin'" href="/user" class="header-link">Users</a>
            <!-- <a v-if="this.role === 'admin'" href="/orderSetting" class="header-link">Settings</a> -->
            <a href="#" class="header-link" @click="logout">Logout</a>
        </div>
    </header>
</template>

<script>
import { useToast } from 'vue-toast-notification';
import reconnectMainSocket, { disconnectSockets } from '@/plugins/socket';
import VueCookie from 'vue-cookie';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();

export default {
    name: 'AppHeader',
    data() {
        return {
            role: 'user',
            homePage: VueCookie.get('allowedIndex').split(',')[0] || 'spx',
            // IBKR status
            ibkrClientStatus: false,
            ibkrServerStatus: false,
            spinningClient: false,
            spinningServer: false,
            // Settings 
            setting: {
                ibkrOrder: false,
                isRecurringSell: false
            }
        }
    },
    mounted() {
        this.role = VueCookie.get('userRole') || 'user'

        if (VueCookie.get('authStatus') === "true") {
            this.connectSocketIO();
        }
    },
    methods: {
        showToast(message, type) {
            $toast.open({
                message,
                type,
                duration: 3000,
                dismissible: true,
                queue: true,
            });
        },

        async toggleIbkrOrder(value) {
            console.log("toggleIbkrOrder", this.setting.ibkrOrder, value)
            if (this.setting.ibkrOrder !== value) {
                this.setting.ibkrOrder = value
                await this.updateSettings()
            }
        },

        async triggerSpinClient() {
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/reconnect`;
            this.spinningClient = true;

            try {
                // Send the reconnect request
                const response = await fetch(url, {
                    method: 'GET', // Set method to POST
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                });

                // Check for a valid response
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // Parse the JSON response
                const result = await response.json();

                // Handle response based on status
                if (result.status === 200) {
                    this.showToast("Reconnect Request Sent Successfully!", "success");
                }
            } catch (error) {
                console.error("Error in triggerSpin:", error.message);
                this.showToast(
                    "Failed to send reconnect request.",
                    "error"
                );
            } finally {
                // Stop spinning regardless of success or failure
                setTimeout(() => {
                    this.spinningClient = false;
                }, 1500)
            }
        },

        async triggerSpinServer() {
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/restart`;
            this.spinningServer = true;

            try {
                // Send the reconnect request
                const response = await fetch(url, {
                    method: 'GET', // Set method to POST
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                });

                // Check for a valid response
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // Parse the JSON response
                const result = await response.json();

                // Handle response based on status
                if (result.status === 200) {
                    this.showToast("Reconnect Request Sent Successfully!", "success");
                }

                window.open("http://spxibkr.sapphiresolutions.in.net")
            } catch (error) {
                console.error("Error in triggerSpin:", error.message);
                this.showToast(
                    "Failed to send reconnect request.",
                    "error"
                );
            } finally {
                // Stop spinning regardless of success or failure
                setTimeout(() => {
                    this.spinningServer = false;
                }, 1500)
            }
        },

        async disconnectIbkr() {
            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/disconnect`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                this.showToast(data.message, 'success');
            } catch (error) {
                console.error(`Error disconnection ibkr:`, error);
            }
        },

        async toggleRecurringSell() {
            console.log("toggleRecurringSell", this.setting.isRecurringSell)
            await this.updateSettings()
        },

        async updateSettings() {
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/setting`;
            const settingData = this.setting

            try {
                const response = await fetch(url, {
                    method: 'POST', // Set method to POST
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                    body: JSON.stringify(settingData), // Convert the data to JSON string
                });

                if (response.ok) {
                    await response.json();
                } else {
                    console.error('Failed to update setting:', response.status);
                }
            } catch (error) {
                console.error('Error during updating setting request:', error);
            }
        },

        async logout() {
            const url = `${process.env.VUE_APP_BACKEND_URL}/api/logout`;
            try {
                // Send the reconnect request
                const response = await fetch(url, {
                    method: 'GET', // Set method to POST
                    headers: {
                        'Content-Type': 'application/json', // Set the content type to JSON
                        'authorization': `Bearer ${VueCookie.get('authToken')}`
                    },
                });

                // Check for a valid response
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // Parse the JSON response
                const result = await response.json();

                // Handle response based on status
                if (result) {
                    VueCookie.delete('authToken')
                    VueCookie.set('authStatus')
                    disconnectSockets()
                    this.$router.push('/')
                }
            } catch (error) {
                console.error("Error in triggerSpin:", error.message);
                this.showToast(
                    "Failed to send reconnect request.",
                    "error"
                );
            }
        },

        connectSocketIO() {
            this.socketMain = reconnectMainSocket()

            if (!this.socketMain.connected) {
                console.log("Forcing socket connection...");
                this.socketMain.connect();
            }

            this.socketMain.on('ibkrStatus', data => {
                if (this.ibkrClientStatus !== data?.ibkrClientStatus) {
                    this.ibkrClientStatus = data?.ibkrClientStatus
                }

                if (this.ibkrServerStatus !== data?.ibkrServerStatus) {
                    this.ibkrServerStatus = data?.ibkrServerStatus
                }
            })

            this.socketMain.on('settings', data => {
                this.setting.ibkrOrder = data.ibkrOrder
                this.setting.isRecurringSell = data.isRecurringSell

                VueCookie.set('ibkrOrder', data.ibkrOrder, { expires: '12h' })
                VueCookie.set('isRecurringSell', data.isRecurringSell, { expires: '12h' })
            })
        }
    }
};
</script>

<style scoped>
a {
    color: black !important;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 1rem;
    background-color: #f2f2f2;
    color: black;
    border-bottom: 1px solid #ddd;
    padding-right: 5rem;
    padding-left: 5rem;
}

.header-left {
    font-size: 1.5rem;
    font-weight: bold;
}

.header-right {
    display: flex;
    gap: 1rem;
}

.header-link {
    text-decoration: none;
    color: #007bff;
}

.header-link:hover {
    text-decoration: underline;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
</style>